<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-menu
        v-model="menu"
        close-on-content-click
        lazy
        transition="scale-transition"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="month"
          prepend-inner-icon="event"
          readonly
        />
        <v-date-picker
          v-model="month"
          :max="thisMonth"
          min="2020-01"
          type="month"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <v-tooltip
        v-if="items.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            color="green"
            v-on="on"
            @click="exportDealerPerformance"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="msisdn"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
          <td
            v-if="props.item.activation"
            class="text-xs-center"
          >
            <v-icon color="success">
              mdi-check
            </v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            <v-icon color="error">
              mdi-close
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ props.item.tier }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'
import zipcelx from 'zipcelx'

const title = 'Activations'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Subscriber Group', disabled: false, to: '/subscriber_group',
        },
        {
          text: title, disabled: true,
        },
      ],
      dealerPerformanceHeader:
        [
          {
            value: 'Entry',
            type: 'string',
          },
          {
            value: 'Msisdn',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'Plan',
            type: 'string',
          },
          {
            value: 'reload',
            type: 'string',
          },
          {
            value: 'Activation',
            type: 'string',
          },
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'Tier',
            type: 'string',
          },
        ],
      headers: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: true,
          value: 'reload',
        },
        {
          text: 'Activation',
          align: 'center',
          sortable: true,
          value: 'activation',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Tier',
          align: 'center',
          sortable: true,
          value: 'tier',
        },
      ],
      items: [],
      loading: false,
      menu: false,
      month: null,
      search: '',
      title: title,
      totalIncentive: 0.00,
      totalReload: 0.00,
      areaDealers: [],
    }
  },
  computed: {
    thisMonth: function () {
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      return now.toFormat('yyyy-LL')
    },
  },
  watch: {
    month: function (val) {
      val && this.getGroupActivation(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.month = now.toFormat('yyyy-LL')
  },
  methods: {
    getGroupActivation: function (month) {
      const param = { month }
      this.loading = true
      this.alert = false
      this.items = []
      this.totalReload = '0.00'
      this.$rest.get('getGroupActivation.php', createGetParams(param))
        .then(function (response) {
          this.items = response.data.item
          this.totalReload = response.data.reload
          if (response.data.message) {
            this.alertMessage = response.data.message
            this.alertType = 'info'
            this.alert = true
          }
          if (response.data.areaDealer) {
            this.dealerPerformanceHeader.push({
              value: 'Area Dealer',
              type: 'string',
            })
            this.areaDealers = response.data.areaDealer
            this.areaDealers.forEach(item => {
              this.adQualifiedTiers += item.qualifiedTiers
              this.adPrepaidGroupRegistration += item.prepaidGroupRegistration
              this.adPrepaidGroupActivation += item.prepaidGroupActivation
              this.adBlackGroupActivation += item.blackGroupActivation
              this.adDealerGroupActivation += item.dealerGroupActivation
              this.adErechargeSales += item.erechargeSales * 1
              this.adDealerAppointment += item.dealerAppointment
            })
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alertType = 'error'
          this.alert = true
          this.loading = false
        })
    },
    exportDealerPerformance: function () {
      const config = {
        filename: security.me.dealerId() + '-GroupActivations-' + this.month,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.dealerPerformanceHeader)
      this.items.forEach(item => {
        const subs = [
          {
            value: item.entry,
            type: 'string',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.plan,
            type: 'string',
          },
          {
            value: item.reload,
            type: 'string',
          },
          {
            value: item.activation,
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.tier,
            type: 'string',
          },
        ]

        this.areaDealers.length && subs.push({
          value: item.leadDealer === security.me.dealerId() ? '-direct-' : item.leadDealer,
          type: 'string',
        })

        config.sheet.data.push(subs)
      })

      zipcelx(config)
    },
  },
}
</script>
